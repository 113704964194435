import React from 'react'
import { Helmet } from "react-helmet";
import "../assets/fonts/fonts.css";


export default function Layout(props) {
    return (
        <>
        <Helmet>
          <html lang="ar" dir="rtl" />
          <title>{props.pageTitle}</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Helmet>

        {props.children}
  
       
      </>
    )
}
